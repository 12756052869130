<template>
	<section class="assessment-form">
		<div class="progress-container">
			<span class="progress-container__text">{{ `${step} / ${Number(totalGroupQuestions)}` }}</span>
			<CProgress :value="step" :max="Number(totalGroupQuestions)" size="xs" />
		</div>

		<ValidationObserver ref="form">
			<form>
				<div class="assessment-form__container question">
					<!-- Loop for questions group list -->
					<template v-for="(questions, idQuestions) in riskQuestions">
						<template v-if="isCurrentStep(idQuestions)">
							<!-- Loop for questions in each questions group -->
							<template v-for="question in questions">
								<!-- question.order: 1-18, question.code: Q1-Q18 in total 18 questions -->
								<div
									v-if="isShowQuestion(question)"
									:key="question.code"
									:class="`form-group question__${question.order}`"
								>
									<label class="form-group__label">{{ question.questionLabel }}</label>
									<ValidationProvider
										v-slot="{ classes, errors }"
										:name="`assessment_${questionCode(question)}`"
										:rules="{ required: question.required }"
									>
										<div :class="['form-group__input grid', classes]">
											<!-- Loop for answers in each question -->
											<div
												v-for="(answer, idAnswer) in question.valueLabel"
												:key="question.code + idAnswer"
												:class="getClassInput(question)"
											>
												<!-- v-model e.g: assessmentForm.q1 -->
												<input
													:id="getIdInput(question, idAnswer)"
													v-model="assessmentForm[questionCode(question)]"
													:type="getInputType(question)"
													:name="inputName(question, idAnswer)"
													:value="answer"
													class="form-check-input"
												/>

												<label
													v-if="
														!isOtherCheck(assessmentForm[questionCode(question)]) ||
														!isOtherCheck(answer)
													"
													class="form-check-label"
													:for="getIdInput(question, idAnswer)"
													>{{ answer }}</label
												>

												<!-- If other input is checked -->
												<label v-else class="w-100" :for="getIdInput(question, idAnswer)">
													<ValidationProvider
														v-slot="{ classes, errors }"
														:name="`assessment_${questionCode(question)}`"
														:rules="{ required: question.required }"
													>
														<div :class="[classes]">
															<!-- v-model e.g: assessmentForm.q1Text -->
															<textarea
																:id="getIdInput(question, idAnswer) + 'text'"
																v-model="
																	assessmentForm[
																		questionCode(question) + $constants.COMMON_CHECK.TEXT
																	]
																"
																rows="1"
																class="form-control other"
																:maxlength="inputOtherLenth"
															></textarea>
															<div class="error-mess">{{ errors[0] }}</div>
														</div>
													</ValidationProvider>
												</label>
											</div>
											<div class="error-mess">{{ errors[0] }}</div>
										</div>
									</ValidationProvider>
								</div>
							</template>
						</template>
					</template>
				</div>

				<hr class="h-divider" />

				<div class="text-right">
					<CButton
						v-if="isShowBack"
						v-bind="{ variant: 'ghost' }"
						color="primary"
						class="mr-3"
						@click="changeStep($constants.STEP.BACK)"
						>{{ $t("RiskAssessment.Back") }}</CButton
					>
					<CButton v-if="isShowNext" color="primary" @click="changeStep($constants.STEP.NEXT)">{{
						$t("RiskAssessment.Next")
					}}</CButton>
					<CButton v-else-if="isFinalStep" color="primary" @click="applyForm">{{
						$t("RiskAssessment.GetResults")
					}}</CButton>
				</div>
			</form>
		</ValidationObserver>
	</section>
</template>

<script>
import {
	STEP,
	COMMON_CHECK,
	ASSESSMENT_KNOW_PREP,
	ASSESSMENT_TESTED,
	ASSESSMENT_QUESTION,
	INPUT_LENGTH,
} from "@/shared/plugins/constants"
import {
	RISK_ASSESSMENT,
	GET_RISK_QUESTIONS,
	RISK_ASSESSMENT_MOBILE,
} from "./../store/action-types"
import { CHANGE_STEP } from "./../store/mutation-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions, mapMutations, mapState } = createNamespacedHelpers("riskAssessment")
import { isArray } from "lodash-es"
export default {
	name: "AssessmentForm",

	props: {
		fullName: {
			type: [String, Number],
			default: null,
		},
		birthYear: {
			type: [String, Number],
			default: null,
		},
		phoneNumber: {
			type: [String, Number],
			default: null,
		},
		isMobile: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			inputOtherLenth: INPUT_LENGTH.OTHER,

			assessmentForm: {},

			userInfo: {},
		}
	},

	computed: {
		...mapState(["step", "riskQuestions", "totalGroupQuestions", "dataAssessment"]),

		isShowBack: function () {
			return this.step !== 1
		},

		isShowNext: function () {
			return this.step !== this.totalGroupQuestions
		},

		isFinalStep: function () {
			return this.step === this.totalGroupQuestions
		},
	},

	watch: {
		dataAssessment: function (val) {
			this.assessmentForm = { ...val }
		},
	},

	async created() {
		this.GET_RISK_QUESTIONS()

		this.setUserInfo()

		await window.parent.postMessage("risk-assessment", "*")
	},

	mounted() {
		// Get data from iframe
		window.addEventListener("message", this.setDataFromIframe, false)
	},

	beforeDestroy() {
		window.removeEventListener("message", this.setDataFromIframe)

		this.CHANGE_STEP(1)
	},

	methods: {
		...mapActions({ RISK_ASSESSMENT, GET_RISK_QUESTIONS, RISK_ASSESSMENT_MOBILE }),
		...mapMutations({ CHANGE_STEP }),

		async applyForm() {
			const check = await this.$refs.form.validate()
			if (!check) return null

			const dataParams = { ...this.assessmentForm }
			for (const key in dataParams) {
				// Replace value = value of text other: dataParams.q1 = dataParams.q1Text
				// Then delelte dataParams.q1Text
				if (isArray(dataParams[key])) {
					const indexOther = dataParams[key].lastIndexOf(COMMON_CHECK.OTHER)
					if (indexOther > -1) dataParams[key][indexOther] = dataParams[key + COMMON_CHECK.TEXT]
				} else {
					if (dataParams[key] === COMMON_CHECK.OTHER)
						dataParams[key] = dataParams[key + COMMON_CHECK.TEXT]
				}
				delete dataParams[key + COMMON_CHECK.TEXT]
			}

			const riskInfo = JSON.parse(localStorage.getItem("riskInfo"))
			const userInfo = this.isMobile ? { ...this.userInfo } : { ...riskInfo }

			this.isMobile
				? this.RISK_ASSESSMENT_MOBILE({
						...userInfo,
						data: JSON.stringify(dataParams),
				  })
				: this.RISK_ASSESSMENT({
						...userInfo,
						data: JSON.stringify(dataParams),
				  })
		},

		setUserInfo() {
			const riskInfo = JSON.parse(localStorage.getItem("riskInfo"))

			if (!this.isMobile) {
				this.userInfo.phoneNumber = this.phoneNumber || riskInfo?.phoneNumber
				this.userInfo.birthYear = this.birthYear || riskInfo?.birthYear
			}

			localStorage.setItem("riskInfo", JSON.stringify(this.userInfo))
		},

		isShowQuestion(question) {
			if (question.code === ASSESSMENT_QUESTION.Q15) {
				return this.assessmentForm.q14 === ASSESSMENT_KNOW_PREP.YES
			}

			if (question.code === ASSESSMENT_QUESTION.Q17) {
				return this.assessmentForm.q16 === ASSESSMENT_TESTED.YES
			}

			return true
		},

		async changeStep(type) {
			if (type === STEP.BACK) this.CHANGE_STEP(this.step - 1)
			if (type === STEP.NEXT) {
				const check = await this.$refs.form.validate()

				if (check) this.CHANGE_STEP(this.step + 1)
			}
			window.parent.postMessage("change-step-eval", "*")
			window.scrollTo(0, 0)
		},

		questionCode(question) {
			return question.code.toLowerCase()
		},

		inputName(question, idAnswer) {
			if (question.type === "checkbox") return this.getIdInput(question, idAnswer)

			return question.code
		},

		getIdInput(question, idAnswer) {
			return question.code.toLowerCase() + idAnswer
		},

		getClassInput(question) {
			return {
				radio: "radio-item",
				checkbox: "checkbox-item",
			}[question.type]
		},

		getInputType(question) {
			return question.type
		},

		isOtherCheck(answer) {
			if (isArray(answer)) return answer.includes(COMMON_CHECK.OTHER)
			return answer === COMMON_CHECK.OTHER
		},

		isCurrentStep(st) {
			return this.step === Number(st)
		},

		setDataFromIframe(event) {
			if (event.data && event.data.idName === "pass-params") {
				if (!this.isAdmin) {
					// Fill user info from mobile device
					this.userInfo.phoneNumber = event.data.params.phone
					this.userInfo.birthYear = event.data.params.birth

					window.removeEventListener("message", this.setDataFromIframe)
				}
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";
.assessment-form {
	@include form;
	@include font-lato;

	&__container {
		display: grid;
		grid-gap: 24px 20px;
		width: 95%;

		// padding: 28px 24px 32px;
		background-color: $color-white;
		border-radius: 12px;

		grid-template-columns: 1fr;
	}
}

.progress-container {
	display: grid;
	grid-template-columns: 50px auto;
	gap: 10px;
	margin-bottom: 25px;

	&__text {
		color: $color-pink;
		font-size: 12px;
		font-weight: bold;
	}

	.progress {
		align-self: center;
	}
}

.question {
	.other {
		@include input-other;
	}
}

.form-group {
	margin-bottom: 0;

	&[stretch] {
		grid-column: 1 / -1;
	}

	&__label {
		margin-bottom: 8px;
		font-weight: bold;
		color: $color-primary2;
		font-size: 16px;

		&[required]::after {
			content: " *";
			color: $color-red;
		}
	}

	&__input {
		&--description /deep/ .textarea__control {
			height: 132px;
		}
	}

	.grid {
		@media (min-width: $xs) {
			grid-template-columns: 1fr 1fr;
		}
	}
}

.form-check {
	.form-check-label {
		font-size: 16px;
	}
}

.grid {
	@include grid-between;
	grid-template-columns: 1fr;
	gap: 5px;

	.error-mess {
		grid-column: 1 / -1;
	}
}

.more-info {
	margin-top: 24px;
	background: $color-snow;
	padding: 16px;
	font-size: 14px;

	&__head {
		font-weight: 500;
		color: $color-gray;
		font-size: 16px;
	}

	ul {
		padding-left: 18px;

		li {
			position: relative;

			a {
				position: absolute;
				left: 96px;
				color: $color-blue;
			}
		}
	}
}

/deep/ .multiselect {
	min-height: 35px;

	&__single,
	&__option {
		display: flex;
		gap: 4px;
	}
	&__tags {
		padding-top: 6px;
		height: 35px;
		min-height: 35px;
		background: $color-black-90;
		border: none;
		border-radius: 5px 5px 0 0;
		border-bottom: 1px solid $color-gray80;
	}
	&__select {
		height: 35px;
	}
	&__single {
		background: rgba(38, 34, 48, 0);
	}

	.option {
		&__image {
			width: 20px;
			height: 20px;
			border-radius: 50%;
			object-fit: cover;
		}

		&__desc {
			align-self: center;
		}
	}
}

// Radio item
.radio-item {
	display: inline-block;
	position: relative;
	padding: 0 6px;
	margin: 10px 0 0;
}

.radio-item input[type="radio"] {
	display: none;
}

.radio-item label:hover {
	cursor: pointer;
}

.radio-item label:before {
	content: " ";
	display: inline-block;
	position: relative;
	top: 3px;
	margin: 0 5px 0 0;
	width: 18px;
	height: 18px;
	border-radius: 11px;
	border: 1px solid $color-gray80;
	background-color: $color-whisper2;
}

.radio-item input[type="radio"]:checked + label:before {
	border: 1px solid $color-primary2;
	background-color: $color-white;
}

.radio-item input[type="radio"]:checked + label:after {
	border-radius: 11px;
	width: 12px;
	height: 12px;
	position: absolute;
	top: 6px;
	left: 9px;
	content: " ";
	display: block;
	background: $color-primary2;
}

// Checkbox item
.checkbox-item {
	display: inline-block;
	position: relative;
	padding: 0 6px;
	margin: 10px 0 0;
}

.checkbox-item input[type="checkbox"] {
	display: none;
}

.checkbox-item label:hover {
	cursor: pointer;
}

.checkbox-item label:before {
	content: " ";
	display: inline-block;
	position: relative;
	top: 3px;
	margin: 0 5px 0 0;
	width: 18px;
	height: 18px;
	border-radius: 4px;
	border: 1px solid $color-gray80;
	background-color: $color-whisper2;
}

.checkbox-item input[type="checkbox"]:checked + label:before {
	border: 1px solid $color-primary2;
	background-color: $color-white;
}

.checkbox-item input[type="checkbox"]:checked + label:after {
	border-radius: 4px;
	width: 12px;
	height: 12px;
	position: absolute;
	top: 6px;
	left: 9px;
	content: " ";
	display: block;
	background: $color-primary2;
}

/deep/ .progress-bar {
	background-color: $color-pink;
}
</style>
